import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Header from '../scenes/Header'
import Posts from '../scenes/Blog/Posts'
import Footer from '../scenes/Footer'

const Blog = ({ intl: { formatMessage }, data }) => {
  const {
    allMarkdownRemark: {
      edges: posts = [],
    } = {},
  } = data
  const remappedPosts = posts.map(({ node }) => node)

  return (
    <Layout>
      <SEO
        metaTitle={formatMessage({ id: 'meta.blog.title' })}
        metaDescription={formatMessage({ id: 'meta.blog.description' })}
      />
      <Navigation />
      <Header
        crumbs={[
          { name: formatMessage({ id: 'breadcrumb.wuha' }), path: '/' },
          { name: formatMessage({ id: 'breadcrumb.blog' }), path: '/blog' },
        ]}
        title={formatMessage({ id: 'blog.header.title' })}
        description={formatMessage({ id: 'blog.header.description' })}
      />
      <Space type="l" />
      <Posts posts={remappedPosts} />
      <Space type="l" />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostsByLang($locale: String!) {
    allMarkdownRemark(
      filter: { 
        frontmatter: { lang: { eq: $locale } } 
        fileAbsolutePath: { regex: "/src/articles/.*.md$/" }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            slug
            title
            tag
            authorPicture
            authorName
            date
          }
          excerpt(pruneLength: 250)
        }
      }
    }
  }
`

export default withContext(injectContext(withIntl(injectIntl(Blog))))
