import './style.css'
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container, Row, Col, Hidden } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Breadcrumb from '../../components/Breadcrumb'
import Button from '../../components/Button'
import Space from '../../components/Space'

const containerClass = classNames({
  'header-container': true,
})

const breadcrumbContainerClass = classNames({
  'header-breadcrumb-container': true,
})

const artworkContainerClass = classNames({
  'header-artwork-container': true,
})

class Header extends Component {

  render() {
    const {
      backgroundPicture,
      title,
      description,
    } = this.props

    return (
      <div className={containerClass}>
        <div style={backgroundPicture && {
          backgroundImage: `url(${backgroundPicture})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}>
          <Container>
            <Hidden xs sm>
              <Row>
                <Col xs={12}>
                  {this.renderBreadcrumb()}
                </Col>
              </Row>
            </Hidden>
            <Row>
              <Col xs={12}>
                {this.renderArtwork()}
                <h1>
                  {title}
                </h1>
              </Col>
            </Row>
          </Container>
        </div>

        <Container>
          <Row>
            <Col xs={12}>
              <Space type="s" />
              <p>
                {description}
              </p>
              {this.renderButton()}
            </Col>
          </Row>
        </Container>
      </div >
    )
  }

  renderBreadcrumb = () => {
    const {
      crumbs,
    } = this.props

    if (!crumbs || !crumbs.length) {
      return null
    }

    return (
      <Fragment>
        <Space type="m" />
        <div className={breadcrumbContainerClass}>
          <Breadcrumb content={crumbs} />
        </div>
        <Space type="thin" />
      </Fragment>
    )
  }

  renderArtwork = () => {
    const {
      artworkPicture,
      artworkWidth,
    } = this.props

    if (!artworkPicture) {
      return null
    }

    return (
      <Fragment>
        <div className={artworkContainerClass}>
          <GatsbyImage
            fluid={artworkPicture}
            width={artworkWidth}
            alt="header"
            style={{
              maxWidth: '100%',
              width: artworkWidth,
            }}
          />
        </div>
        <Space type="l" />
      </Fragment>
    )
  }

  renderButton = () => {
    const {
      actionText,
      actionURL,
      actionNewTab,
    } = this.props

    if (!actionText) {
      return null
    }

    return (
      <div>
        <Space type="l" />
        <a
          href={actionURL}
          target={ actionNewTab ? "_blank" : ""}
          rel={ actionNewTab ? "noopener noreferrer" : ""}
          style={{
            display: 'inline-block',
          }}
        >
          <Button
            text={actionText}
            color="red"
            isExpanded={false}
          />
        </a>
      </div>
    )
  }
}

Header.propTypes = {
  crumbs: PropTypes.array.isRequired,
  backgroundPicture: PropTypes.string,
  artworkPicture: PropTypes.object,
  artworkWidth: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  actionText: PropTypes.string,
  actionURL: PropTypes.string,
  actionNewTab: PropTypes.bool,
}

Header.defaultProps = {
  artworkPicture: undefined,
  artworkWidth: undefined,
  backgroundPicture: undefined,
  title: undefined,
  description: undefined,
  actionText: undefined,
  actionURL: undefined,
  crumbs: undefined,
  actionNewTab: false,
}

export default Header
