import React, { Component } from 'react'
import PropTypes from 'prop-types'
import toMaterialStyle from 'material-color-hash'

class Tag extends Component {

  render() {
    const {
      name,
    } = this.props

    const materialColor = toMaterialStyle(name)
    const style = {
      color: materialColor.backgroundColor,
      backgroundColor: `${materialColor.backgroundColor}33`,
      fontSize: 12,
      borderRadius: 180,
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 16,
      paddingLeft: 16,
      textTransform: 'uppercase',
      display: 'inline-block',
    }

    return (
      <div style={style}>
        {name}
      </div>
    )
  }
}

Tag.propTypes = {
  name: PropTypes.string.isRequired,
  uri: PropTypes.string,
}

Tag.defaultProps = {
  name: '',
  uri: undefined,
}

export default Tag
