import './style.css'
import React, { Component, Fragment } from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

import Space from '../../../components/Space'
import Post from './components/Post'

const containerClass = classNames({
  'blog-posts-container': true,
})

class Posts extends Component {

  render() {
    return (
      <Container>
        <Row>
          <Col xs={12} md={8} offset={{ md: 2 }}>
            <div className={containerClass}>
              {
                this.props.posts.map((post) => (
                  <Fragment key={post.id}>
                    <Post
                      path={post.frontmatter.slug}
                      title={post.frontmatter.title}
                      tag={post.frontmatter.tag}
                      author={{
                        picture: post.frontmatter.authorPicture,
                        name: post.frontmatter.authorName,
                      }}
                      date={post.frontmatter.date}
                      excerpt={post.excerpt}
                    />
                    <Space type="l" />
                  </Fragment>
                ))
              }
            </div>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default injectIntl(Posts)
