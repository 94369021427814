import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { injectIntl, FormattedMessage } from 'react-intl'
import { ScreenClassRender } from 'react-grid-system'

import Button from '../../../../../components/Button'
import I18nLink from '../../../../../components/I18nLink'
import Image from '../../../../../components/Image'
import Inline from '../../../../../components/Inline'
import Space from '../../../../../components/Space'
import Tag from '../../../../../components/Tag'

import smallArrowRightBlueIcon from '../../../../../img/icons/icon_small_arrow_right_blue.svg'

const containerClass = classNames({
  'blog-posts-post-item-container': true,
})

const authorContainerClass = classNames({
  'blog-posts-post-item-author-container': true,
})

const authorTextClass = classNames({
  'blog-posts-post-item-author-text': true,
})

const excerptTextClass = classNames({
  'blog-posts-post-item-excerpt-text': true,
})

class Post extends Component {

  render() {
    const {
      path,
      title,
      tag,
      author: {
        picture,
        name,
      } = {},
      date,
      excerpt,
      intl: {
        formatMessage,
      },
    } = this.props

    const picturePath = require(`../../../../../img/team/${picture}`)
    const dateText = new Date(Date.parse(date)).toLocaleDateString()

    return (
      <div className={containerClass}>
        <Tag name={tag} />
        <Space type="xs" />
        <I18nLink to={path}>
          <h2>
            {title}
          </h2>
        </I18nLink>
        <Space type="xxs" />
        <div className={authorContainerClass}>
          <Image
            src={picturePath}
            alt={picture}
            height={24}
            width={24}
          />
          <Inline type="xxs" />
          <p className={authorTextClass}>
            <FormattedMessage
              id="blog.posts.post.authorDate"
              values={{
                name: <b>{name}</b>,
                date: <b>{dateText}</b>,
              }}
            />
          </p>
        </div>
        <Space type="xs" />
        <p className={excerptTextClass}>
          {excerpt}
        </p>
        <Space type="m" />
        <I18nLink to={path}>
          <ScreenClassRender render={screenClass => (
            <Button
              color="outline"
              text={formatMessage({ id: 'blog.posts.post.action.readMore' })}
              iconRight={smallArrowRightBlueIcon}
              isExpanded={['xs', 'sm'].includes(screenClass)}
            />
          )} />
        </I18nLink>
      </div>
    )
  }
}

Post.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tag: PropTypes.string.isRequired,
  author: PropTypes.shape({
    picture: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
}

Post.defaultProps = {
  path: undefined,
  title: undefined,
  tag: undefined,
  author: undefined,
  date: undefined,
  excerpt: undefined,
}

export default injectIntl(Post)
